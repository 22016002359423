<template>
    <div class="content-wrapper">
        <rq-page-section headerSize="lg" borderless header-only>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <b-button :automation_id="elementName('btn_add')" :ref="elementName('btn_add')"
                            class="btn btn-sm btn-theme me-1" variant="theme" @click="onAddDocuments"
                            v-rq-tooltip.hover.top="{ title: `Add Document(s)` }">Add Document
                        </b-button>
                    </li>
                </ul>
            </template>
        </rq-page-section>
        <rqdx-action-data-grid ref="dataGrid" :automation_id="elementName('tbl')" :actions="selectionActions"
            :config="gridConfig" :data-source="gridDataSource"
            integrated-search rq-filters rq-editable @preview="onPreviewItem" @delete="onDeleteItem">
        </rqdx-action-data-grid>
    </div>
</template>

<script>
import { useGridInvokerMethods, useGridDocumentTemplatePicker } from "@/shared/composables/";
import BuyerSellerDocumentAdd from "./BuyerSellerDocumentAdd.vue";
import { BuyerSellerDocumentDto } from "@/modules/file/order-entry/buyer-seller/models.js";
import DocumentPreview from "@/shared/components/rq/dialogs/DocumentPreview.vue";
import PdfViewer from "@/shared/components/rq/dialogs/EditPdfDialog";

export default {
    name: "BuyerSellerDocumentList",
    props: {
        buyerSellerID: { type: Number, default: 0 },
    },
    setup() {
        const { dataGrid, invokeGridMethod, invokeGridComponentMethod } =
            useGridInvokerMethods();

        const { getDocumentTemplateGridColumn } = useGridDocumentTemplatePicker();

        return {
            dataGrid,
            invokeGridMethod,
            invokeGridComponentMethod,
            getDocumentTemplateGridColumn,
        };
    },
    data() {
        return {
            items: [],
            itemData: [],
            originalItemData: null,
            errorMessage: "",
            users: [],
        }
    },

    computed: {
        gridInstance() {
            return _.get(this.$refs, "dataGrid.gridInstance", null);
        },
        selectionActions() {
            return [
                {
                    name: "Preview",
                    text: "Preview",
                    eventName: "preview",
                    allowMultiSelection: false,
                    requireSelection: true,
                    tooltip: `Preview ${this.itemTypeName}`,
                },
                {
                    name: "delete",
                    text: "Delete",
                    eventName: "delete",
                    allowMultiSelection: true,
                    requireSelection: true,
                    tooltip: `Delete ${this.itemTypeName}`,
                },

            ];
        },
    },

    created() {
        const self = this;
        self.fetchData();
        self.initNonReactiveVariables();
        self.initGridConfig();
    },

    methods: {

        elementName(prefix = "", suffix = "") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },
        initNonReactiveVariables() {
            const self = this;
            self.itemTypeName = "Document";
            self.itemTypeNamePlural = "Documents";
            self.itemKey = "buyerSellerDocumentID";
        },
        initGridConfig() {
            const self = this;

            self.gridConfig = {
                scrolling: { useNative: true },
                sorting: { enabled: true },
                columns: [
                    {
                        dataField: "buyerSellerDocumentID",
                        dataType: "number",
                        caption: "BuyerSellerDocumentID",
                        visible: false
                    },
                    {
                        dataField: "fileName",
                        dataType: "string",
                        caption: "File Name",
                        allowEditing: false
                    },
                    {
                        dataField: "contentType",
                        dataType: "string",
                        caption: "File Type",
                        allowEditing: false
                    },
                    {
                        dataField: "effectiveDate",
                        dataType: "date",
                        caption: "Effective Date",
                        validationRules:
                            [
                                {
                                    type: "custom",
                                    message: "Effective Date is Required.",
                                    validationCallback: e => !_.isNullOrEmpty(e.data.effectiveDate)
                                },
                                {
                                    reevaluate: true,
                                    type: "custom",
                                    message: "Effective Date cannot be after Expiration Date",
                                    validationCallback: (e) => {
                                        if (_.isNullOrEmpty(e.data.expireOnDate)) {
                                            return true;
                                        }
                                        if (new Date(e.data.expireOnDate) < new Date(e.data.effectiveDate)) {
                                            return false;
                                        }
                                        return true;
                                    }
                                }
                            ]
                    },
                    {
                        dataField: "expireOnDate",
                        dataType: "date",
                        caption: "Expiration Date",
                        validationRules: [
                            {
                                reevaluate: true,
                                type: "custom",
                                message: "Expiration Date cannot be before Effective Date",
                                validationCallback: (e) => {
                                    if (_.isNullOrEmpty(e.data.expireOnDate)) {
                                        return true;
                                    }
                                    if (new Date(e.data.effectiveDate) > new Date(e.data.expireOnDate)) {
                                        return false;
                                    }
                                    return true;
                                }
                            }
                        ]
                    },
                    {
                        dataField: "modifiedByName",
                        dataType: "string",
                        caption: "Modified By",
                        allowEditing: false
                    },
                    {
                        dataField: "modifiedDate",
                        dataType: "date",
                        caption: "Date Modified",
                        visible: true,
                        allowEditing: false
                    },
                ],
                onEditingStart(e) {
                    self.gridInstance.clearSelection();
                    self.originalItemData = e.data;
                },
            };
            self.gridDataSource = {
                key: self.itemKey,
                update: self.onGridUpdate,
                load(loadOptions) {
                    return Promise.resolve(self.items);
                },
            };
        },

        onAddDocuments(e) {
            const self = this;
            let onCancel = (e) => {
                return true;
            }
            let onOk = (e) => {
                let form = e.component;
                form.saveDocuments()
                    .then(result => {
                        result.rejectedScannedDocuments.forEach(doc => {
                            self.$toast.error(`${doc.responseMessage}`)
                        })
                        self.fetchData();
                    }).catch(e => {
                        console.log(e.errorMessage);
                        self.$toast.error({ message: e.errorMessage });
                        return false;
                    })
                    .finally(() => {
                        self.refresh();
                        return true;
                    });
            };
            let existingItems = [];
            _.each(self.items, i => {
                let existingItem = _.pick(i, ["contentType", "buyerSellerDocumentID", "fileName"]);
                existingItems.push(existingItem);
            });
            self.$dialog.open({
                title: `Add Buyer/Seller Document(s)`,
                props: {
                    existingItems: existingItems,
                    buyerSellerID: self.buyerSellerID

                },
                maxHeight: window.innerHeight > 768 ? "90%" : 700,
                width: window.innerWidth > 1367 ? "90%" : 1200,
                minHeight: 500,
                minWidth: 1100,
                // resizable: false,
                // scrollable: false,
                adaptive: true,
                closeOnEsc: false,
                component: BuyerSellerDocumentAdd,
                okTitle: 'Upload',
                onOk: onOk,
                onCancel: onCancel,
            });
        },

        fetchData() {
            const self = this;
            let apiPromise = self.$api.BuyerSellerDocumentApi.getAll(self.buyerSellerID);

            return self.$rqBusy
                .wait(apiPromise)
                .then((result) => {
                    self.items = _.map(result, (i) => new BuyerSellerDocumentDto(i));
                    self.refresh();
                })
                .catch((error) => {
                    console.log(error);
                    self.$toast.error({
                        message: `Error loading ${self.itemTypeNamePlural}.`,
                    });
                    return error;
                });
        },

        onDeleteItem(e) {
            if (!e || !e.data) return;

            const self = this;
            let items = e.data;
            let itemLabel =
                items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName;

            let okHandler = function (args) {
                let keys = _.map(items, self.itemKey);
                self.delete(keys);
                return true;
            };

            self.$dialog.confirm(
                "Confirm Delete",
                `Are you sure you want to delete the selected ${itemLabel}?`,
                okHandler,
                null,
                { cancelTitle: "No", okTitle: "Yes" }
            );
        },

        delete(keys) {
            const self = this;
            let apiPromise = self.$api.BuyerSellerDocumentApi.deleteBuyerSellerDocument(keys);
            return self.$rqBusy
                .wait(apiPromise)
                .then(() => {
                    self.removeItems(keys);
                    let message =
                        keys.length > 1
                            ? `${keys.length} ${self.itemTypeNamePlural} were deleted.`
                            : `${self.itemTypeName} was deleted.`;
                    self.$toast.success(message);
                    return true;
                })
                .catch((error) => {
                    if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                        self.$dialog.messageBox(
                            `Delete Error`,
                            `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`
                        );
                    } else {
                        self.$toast.error(`Error deleting ${self.itemTypeName}.`);
                    }
                    console.error(error);
                    return error;
                });
        },

        removeItems(keys) {
            const self = this;
            _.forEach(keys, (key) => {
                let itemIndex = _.findIndex(
                    self.items,
                    (item) => item[self.itemKey] === key
                );
                if (itemIndex >= 0) self.items.splice(itemIndex, 1);
            });
            self.refresh();
        },

        refresh() {
            if (_.isNull(this.gridInstance)) return;
            this.gridInstance.clearSelection();
            this.gridInstance.refresh();
        },

        onGridUpdate(key, values) {
            const self = this;
            let updatedItem = new BuyerSellerDocumentDto(_.assign({}, self.originalItemData, values));
            let changes = self.getAuditChanges(self.originalItemData, updatedItem);
            return self.save(updatedItem, changes)
                .then(result => {
                    self.originalItemData = null;
                    return updatedItem;
                });
        },

        save(item, changes) {
            const self = this;
            if (_.isEmpty(changes) && item.buyerSellerDocumentID > 0) {
                return Promise.resolve(item);
            }
            let apiPromise = self.$api.BuyerSellerDocumentApi.save(item);
            return self.$rqBusy.wait(apiPromise)
                .then(result => {
                    self.fetchData();
                    self.$toast.success(`The ${self.itemTypeName}, ${item.fileName}, was saved.`);
                    return result;
                }).catch(error => {
                    self.$toast.error(`Error saving ${self.itemTypeName}.`);
                    console.error(error);
                    return error;
                });
        },

        async onPreviewItem(e) {
            const self = this;
            let buyerSellerDocumentID = e.data.buyerSellerDocumentID;
            let promise = self.$api.BuyerSellerDocumentApi.getBuyerSellerDocumentFile(buyerSellerDocumentID);
            let result = await self.$rqBusy.wait(promise);

            if (result.contentType === "application/pdf") {
                self.$dialog.open({
                    title: "Preview",
                    height: "85%",
                    width: "85%",
                    resizable: true,
                    scrollable: false,
                    overflowVisble: true,
                    component: PdfViewer,
                    props: {
                        content: result.dataUrl,
                        fileName: result.fileName,
                        contentType: result.contentType,
                        readOnly: true
                    },
                    okTitle: "Close",
                    okOnly: true,
                });
            }
            else {
                self.$dialog.open({
                    title: "Preview",
                    height: "85%",
                    width: "1050",
                    resizable: false,
                    component: DocumentPreview,
                    props: {
                        content: result.dataUrl,
                        fileName: result.fileName,
                        contentType: result.contentType,
                        readOnly: true
                    },
                    okTitle: "Close",
                    okOnly: true,
                });
            }
        },
    },
};
</script>